:root{--color-pri: #00fefe;--color-pri-light: #32ffff;--color-pri-dark: #00cbcb;--text-pri: #000;--color-sec: #7d6bff;--color-sec-light: #aa9eff;--color-sec-dark: #5038ff;--text-sec: #fff;--color-ter: #fa8efd;--color-ter-light: #fcc0fe;--color-ter-dark: #f85cfc;--text-ter: #000;--color-grey: #898f8f;--color-grey-light: #a3a8a8;--color-grey-dark: #707575;--text-ter: #fff;--color-bg-light: #f6f6f2;--color-bg-dark: #202020;--color-bg: var(--color-bg-light);--color-success: #9fd4aa;--text-success: #000;--color-warning: #ffc869;--text-warning: #000;--color-error: #da3b3e;--text-error: #fff;--color-info: #4579ae;--text-warning: #fff;--linear-gradient: linear-gradient(
    30deg,
    var(--color-pri),
    var(--color-sec),
    var(--color-ter)
  );--radial-gradient: radial-gradient(
    circle at center,
    var(--color-pri),
    var(--color-sec),
    var(--color-ter)
  );--conic-gradient: conic-gradient(
    var(--color-pri),
    var(--color-sec),
    var(--color-ter)
  )}:root{--font-title: "GT Super WT";--font-text: "Circular Std";--font-system: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Segoe UI Emoji"}:root{--shadow-pri: 5px 5px 20px 0 var(--color-pri 50%);--shadow-sec: 5px 5px 20px 0 var(--color-sec 50%);--shadow-ter: 5px 5px 20px 0 var(--color-ter 50%);--inset-pri: inset 5px 5px 20px 0 var(--color-pri 50%);--inset-sec: inset 5px 5px 20px 0 var(--color-sec 50%);--inset-ter: inset 5px 5px 20px 0 var(--color-ter 50%)}*,*:before,*:after{box-sizing:border-box;margin:0;padding:0;border:none;outline:none;box-shadow:none;image-rendering:crisp-edges;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-rendering:optimizeLegibility;-webkit-tap-highlight-color:transparent}*:active,*:before:active,*:after:active{-webkit-tap-highlight-color:transparent}:root{font-family:var(--font-system);font-size:100%;font-weight:lighter;line-height:1.4;vertical-align:baseline}html,body{position:relative;width:100%;height:100%;overflow:hidden}html{background:#000}body{background:var(--color-bg);border-radius:0.5em}#app{position:absolute;inset:0;width:100%;height:100%}pre,code{font-family:"Courier New", Courier, monospace, "Segoe UI Emoji"}:is(h1,h2,h3,h4,h5,h6){line-height:1.2}h1{font-size:2.5em}h2{font-size:2em}h3{font-size:1.5em}h4{font-size:1em}h5{font-size:0.9em}h6{font-size:0.7em}ul,ol{list-style:none}table{border-collapse:collapse;border-spacing:0}::-moz-selection{background-color:var(--color-pri);color:var(--text-pri)}::selection{background-color:var(--color-pri);color:var(--text-pri)}@media (prefers-reduced-motion: reduce){*{transition:none !important;-webkit-animation:none !important;animation:none !important;scroll-behavior:auto !important}}
